import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Modal from 'react-modal'; // Import the modal library
import './assets/css/mainb874.css';
import { BlinkBlur } from 'react-loading-indicators';
const ShopInternet = React.lazy(() => import('./Pages/ShopSpectrumInternet'));
const ShopTv = React.lazy(() => import('./Pages/ShopspectrumTv'));
const Deal = React.lazy(() => import('./Pages/SpectrumDeal'));
const Bundles = React.lazy(() => import('./Pages/SpectrumBundles'));
const MOBILEPLANS = React.lazy(() => import('./Pages/SPECTRUMMOBILEPLANS'));
const Cahnnel = React.lazy(() => import('./Pages/Spectrumcahnnel'));
const Voice = React.lazy(() => import('./Pages/Spectrumvoice'));
const InternetRevew = React.lazy(() => import('./Pages/SpectrumInternetRevew'));
const Tvreview = React.lazy(() => import('./Pages/SpectrumInternetRevew'));
const Cheapestinternet = React.lazy(() => import('./Pages/Cheapestinternet'));
const Bundlesreview = React.lazy(() => import('./Pages/Spectrumbundlesreview'));
const Mobilereview = React.lazy(() => import('./Pages/Spectrummobilereview'));
const Contact = React.lazy(() => import('./Pages/Contact'));
const ZipcodeResponse = React.lazy(() => import('./Pages/ZipcodeResponse'));
const Blog = React.lazy(() => import('./Pages/Blog'));
const Navbar = React.lazy(() => import('./Main/Navbar'));
const Home = React.lazy(() => import('./Pages/Home'));
const Footer = React.lazy(() => import('./Main/Footer'));
// import { useEffect, useState } from 'react';
// import Navbar from './Main/Navbar';
// import Home from './Pages/Home';
// import Footer from './Main/Footer';
// import ShopInternet from './Pages/ShopSpectrumInternet';
// import ShopTv from './Pages/ShopspectrumTv';
// import Deal from './Pages/SpectrumDeal';
// import Bundles from './Pages/SpectrumBundles';
// import MOBILEPLANS from './Pages/SPECTRUMMOBILEPLANS';
// import Cahnnel from './Pages/Spectrumcahnnel';
// import Voice from './Pages/Spectrumvoice';
// import InternetRevew from './Pages/SpectrumInternetRevew';
// import Tvreview from './Pages/SpectrumTvreview';
// import Cheapestinternet from './Pages/Cheapestinternet';
// import Bundlesreview from './Pages/Spectrumbundlesreview';
// import Mobilereview from './Pages/Spectrummobilereview';
// import Contact from './Pages/Contact';
// import ZipcodeResponse from './Pages/ZipcodeResponse';
// import Blog from './Pages/Blog';

// Modal style
const customStyles = {
  overlay: {
    zIndex: 9999, // Ensuring the overlay is above other elements
  },
  content: {
    top: '50%',
    left: '50%',
    borderRadius:'20px',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 9999, // Set a high z-index value
  },
};

Modal.setAppElement('#root'); // Set the app root for accessibility

function App() {
  const [modalIsOpen, setModalIsOpen] = useState(true);

  // This effect ensures the modal opens every 1 minute
  useEffect(() => {
    const timer  = setInterval( async() => {
     await setModalIsOpen(true);
    }, 30000); // 60000 milliseconds = 1 minute

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, []);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    if (window.scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }, []);


  return (
    <>
      <BrowserRouter>
      <Suspense fallback={<div className='loader-usa'><BlinkBlur color="#cc3138" size="medium" text="" textColor="" /></div>}>

        <Navbar />

        {/* Modal that opens every minute */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Contact Form"
        >
          <h2>Netwave Tech</h2>
          <form  style={{width:''}} className='modalCss' >
            <div>
              <label>Name: </label>
              <input type="text" placeholder="Enter your name" />
            </div>
            <div>
              <label>Email: </label>
              <input type="email" placeholder="Enter your email" />
            </div>
            <div>
              <label>Message: </label>
              <textarea placeholder="Enter your message"></textarea>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between' ,}}>

              <button  style={{cursor:'pointer' ,backgroundColor:'black',color:'white', padding:'10px'}}  onClick={closeModal}>Close</button>
              <button style={{cursor:'pointer' ,backgroundColor:'black',color:'white', padding:'10px'}} type="submit" onClick={closeModal}>Submit</button>
            </div>

          </form>
        </Modal> 
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/shopinternet' element={<ShopInternet />} />
          <Route path='/shoptv' element={<ShopTv />} />
          <Route path='/Deal' element={<Deal />} />
          <Route path='/Bundles' element={<Bundles />} />
          <Route path='/MOBILEPLANS' element={<MOBILEPLANS />} />
          <Route path='/Channel' element={<Cahnnel />} />
          <Route path='/Voice' element={<Voice />} />
          <Route path='/internetreview' element={<InternetRevew />} />
          <Route path='/Tvreview' element={<Tvreview />} />
          <Route path='/Cheapestinternet' element={<Cheapestinternet />} />
          <Route path='/bundlesreview' element={<Bundlesreview />} />
          <Route path='/mobilereview' element={<Mobilereview />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/blog' element={<Blog />} />
          <Route path='/zipresponse/:id' element={<ZipcodeResponse />} />
        </Routes>
        <Footer />
</Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;

